@import '../../variable';

.arrowIcons {
  width: 30px;
}

.accordionContainer {
  padding: 16px 0;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }

  &::after {
    content: '';
    position: absolute;
    background: $black100;
    height: 2px;
    right: -16px;
    left: -16px;
    top: 0;
  }

  .btnContainer {
    .accordionBtn {
      margin-right: 16px;
    }

    .titleContainer {

      &:nth-child(2) {

        .accordionData,
        .accordionLabel {
          font-size: unit(16px);
          font-weight: 400;
        }

      }
    }
  }

  .titleContainer {
    color: $white;
    font-size: unit(18px);
    font-weight: 500;

    .accordionLabel {
      color: $skyBlue100;
    }
  }

  .accordionContent {
    .accordionRow {
      .titleContainer {
        flex: 0 0 50%;
        max-width: 50%;
        margin: 32px 0;

        .accordionLabel {
          margin-bottom: 16px;
        }

        .accordionDataBtn {
          width: 75%;
        }
      }

      &:last-child {
        .titleContainer {
          flex: 0 0 100%;
          max-width: 100%;

          .detailsBtnContainer {
            width: 100px;
            margin-left: 32px;

            .detailsBtn {
              width: 100%;
              font-size: unit(14px);
              text-transform: capitalize;
              border: 2px solid $lightBlue100;
              background: $blue100;
              color: white;
            }
          }
        }
      }
    }
  }

}