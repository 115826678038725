@import '../../variable';

.passportContainer {
  .label {
    margin-bottom: 16px;
    color: $lightGray100;
    font-weight: 400;
  }

  .firstColumn {
    .flexContainer {
      margin-bottom: 16px;

      .flexDivContainer {
        .flexDiv {
          margin-bottom: 8px;
          background: $lightGray100;
          // flex: 0 0 48%;
          // max-width: 48%;
          border-radius: 5px;
          color: $black50;
          font-weight: 400;
        }

      }
    }
  }

  .secondColumn {
    .attachmentContainer {
      margin-top: 16px;
      color: white;
    }
  }
}

@media (min-width: $width992) {
  .passportContainer {
    .label {
      margin-bottom: 0;
    }

    .firstColumn {
      flex: 0 0 78%;

      .flexContainer {
        margin-bottom: 0;

        .label {
          flex: 0 0 15%;
        }

        >div {
          flex: 0 0 55%;
        }

        .flexDivContainer {
          flex: 0 0 76%;

          .flexDiv {
            margin-bottom: 0;
            flex: 0 0 32%;
            max-width: 32%;
          }

        }
      }

      >div {
        margin-bottom: 32px;
      }
    }

    .secondColumn {
      flex: 0 0 22%;
    }
  }
}