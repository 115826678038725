@import '../../../variable';

.headerContainer {
  p {
    color: $lightGray100;
    font-weight: 400;
  }

  .titleText,
  .inputField,
  .headerDec {
    margin-bottom: 16px;
  }

  .headerDec {
    font-size: (14px);
    font-weight: 300;
  }

  >div {
    &:nth-child(2) {
      .titleText {
        margin-bottom: 0;
      }
    }
  }
}

.projectContainer {
  >div {
    &:nth-child(2) {
      margin-bottom: 16px;

      >div {
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
    }

    &:nth-child(3) {
      .addTaskContainer {
        .addBtnContainer {
          background: $gray75;
          padding: unit(24px) 0;
          border-radius: 5px;

          p {
            margin: 0;
            margin-left: 16px;
          }

          .btnIcon {
            width: 20px;
            height: 20px;

            >svg {
              fill: white;
            }
          }
        }
        .disabledAddBtnContainer{
          opacity: 0.4;
        }
      }

      .taskDropdownContainer {
        margin-top: 16px;

        >div {
          flex: 48%;
          max-width: 48%;

          p {
            font-weight: 300;
          }
        }
      }
    }
  }
}

.btnContainer {
  >div {
    &:nth-child(1) {
      margin-bottom: 16px;
    }
  }
}

@media (min-width: $width992) {
  .titleText {
    flex: 0 0 10%;
  }

  .headerContainer {

    .titleText,
    .inputField,
    .headerDec {
      margin-bottom: 0;
    }

    .headerDec {
      font-size: (16px);
    }

    >div {
      margin-bottom: 16px;
    }
  }

  .projectContainer {
    margin-bottom: 16px;

    >div {
      &:nth-child(2) {
        margin-bottom: 0;
        flex: 0 0 65%;
        max-width: 65%;
      }

      &:nth-child(3) {
        padding: 0 unit(16px) 0 unit(32px);
        flex: 0 0 25%;
        max-width: 25%;

        .addTaskContainer {
          .addBtnContainer {
            position: relative;
            padding: unit(32px) 0;

            p {
              margin-left: 0;
              position: absolute;
              top: -40px;
              left: 0;
              color: $lightGray100;
            }

            .btnIcon {
              width: 70px;
              height: 70px;
            }
          }
        }

        .taskDropdownContainer {
          margin-top: 32px;

          >div {
            flex: 48%;
            max-width: 48%;

            p {
              font-weight: 400;
              color: $lightGray100;
            }
          }
        }
      }
    }
  }

  .btnContainer {
    >div {
      &:nth-child(1) {
        margin-bottom: 0;
        flex: 0 0 70%;
        margin-left: -18px;
      }

      &:nth-child(2) {
        flex: 0 0 30%;
      }
    }
  }
}