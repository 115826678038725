@import '../../variable';

.timerContainer {
  p {
    color: $lightGray100;
    text-transform: uppercase;
    font-size: unit(12px);
    font-weight: 500;
    margin-bottom: 10px;
  }

  >div:first-child {
    margin-bottom: 32px;
  }

  .clockContainer {
    background: $white;
    border-radius: 5px;
    padding: unit(4px);
    width: 75%;
    
    >div {
      p {
          margin: 0;
          font-size: unit(20px);
        }
      &:nth-child(2) {
        background: $black50;
        color: $white;
        padding: unit(6px);
        border-radius: 10px;
        flex: 0 0 45px;
        max-width: 45px;
        height: 45px;
        text-align: center;
      }

      &:nth-child(1) {
        width: calc(100% - 65px);
        margin-right: 16px;
      }
    }

    .inputField {
      width: 100%;
      background: transparent;
      border: 0;
      text-align: center;
      font-size: unit(28px);
      font-weight: 500;
      color: $black50;
      padding: 0;
    }
  }
}