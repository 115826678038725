@import '../../variable';

.contractContainer {
  .label {
    margin-bottom: 16px;
    color: $lightGray100;
    font-weight: 400;
  }

  .flexContainer {
    margin-bottom: 16px;

    .flexDiv {

      >div {
        flex: 0 0 48%;
        max-width: 48%;
        color: $black50;
        font-weight: 400;
        background: $lightGray100;
        border-radius: 5px;

      }
    }
  }

  .dateMainContainer {
    .dateContainer {
      >div {
        flex: 0 0 48%;
        max-width: 48%;
      }
    }
  }

  .addBtnContainer {
    margin-top: 16px;

    button {
      background: $gray75;
      color: white;
    }
  }
}

@media (min-width: $width992) {
  .contractContainer {
    >div {
      &:nth-child(1) {
        margin-bottom: 64px;
      }
    }

    .flexContainer {

      // margin-bottom: 16px;
      .label {
        flex: 0 0 12%;
        max-width: 12%;
        margin-bottom: 0;
      }

      >div {
        flex: 0 0 45%;
        max-width: 45%;

        .label {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 16px;
        }
      }

      .flexDiv {
        >div {
          flex: 0 0 35%;
          max-width: 35%;
          margin-right: 16px;
        }
      }
    }

    .dateMainContainer {
      .label {
        flex: 0 0 12%;
        max-width: 12%;
        margin-bottom: 0;
      }

      >div {
        flex: 0 0 45%;
        max-width: 45%;
      }

      .dateContainer {
        >div {
          flex: 0 0 40%;
          max-width: 40%;

          &:nth-child(2) {
            flex: 0 0 60%;
            max-width: 60%;

            .label {
              flex: 0 0 20%;
              max-width: 20%;
              margin-left: 16px;
            }

            >div {
              flex: 0 0 70%;
              max-width: 70%;
            }
          }
        }
      }
    }

    .flexContainer {
      .addBtnContainer {
        flex: 0 0 18%;
        max-width: 18%;
      }
    }
  }
}