@import '../../variable';

.visaContainer {
  .label {
    margin-bottom: 16px;
    color: $lightGray100;
    font-weight: 400;
  }

  .flexContainer {
    margin-bottom: 16px;

    .flexDiv {
      >div {
        flex: 0 0 48%;
        max-width: 48%;
        color: $black50;
        font-weight: 400;
        background: $lightGray100;
        border-radius: 5px;
      }
    }
  }

  .dateContainer {
    >div {
      >div {
        flex: 0 0 48%;
        max-width: 48%;
      }
    }
  }
}

@media (min-width: $width992) {
  .visaContainer {
    .label {
      margin-bottom: 0;
    }

    .flexContainer {
      margin-bottom: 32px;

      .label {
        flex: 0 0 12%;
        max-width: 12%;
      }

      >div {
        flex: 0 0 35%;
        max-width: 35%;

        .label {
          flex: 0 0 auto;
          max-width: 100%;
        }
      }

      .flexDiv {
        >div {
          flex: 0 0 40%;
          max-width: 40%;

          &:nth-child(1) {
            margin-right: 16px;
          }
        }
      }
    }

    .dateContainer {
      .label {
        flex: 0 0 12%;
        max-width: 12%;
      }

      >div {
        flex: 0 0 35%;
        max-width: 35%;

        >div {
          flex: 0 0 37%;
          max-width: 37%;

          &:nth-child(2) {
            flex: 0 0 62%;
            max-width: 62%;

            .label {
              flex: 0 0 35%;
              max-width: 35%;
              text-align: center;
            }

            >div {
              flex: 0 0 65%;
              max-width: 65%;
            }

          }

        }
      }
    }


  }
}