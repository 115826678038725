@import "../../variable";

.calendarBtnContainer {
  padding: unit(16px) unit(32px);

.calendarLabel {
    font-size: unit(22px);
    font-weight: 400;
  }

  .calendarBtn {
    background: transparent;
    border: 0;
    display: block;

    .calendarIcon {
      width: 20px;
      height: auto;
      display: block;
      g {
        fill: $gray75;
      }
    }
  }
}

