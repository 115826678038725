@import '../../variable';

.header {
  height: 100%;

  .inputContainer {
    flex: 0 0 30%;
  }

  .headerContent {
    .messages,
    .notification {
      width: 24px;
      margin-right: 16px;

      .mailBellImg {
        fill: rgba($blue50, 0.6);
      }
    }

    .profileName {
      height: 100%;
      margin: 0 16px;
      padding-right: 16px;
      border-right: 1px solid $lightGray75;

      p {
        &:nth-child(1) {
          color: $gray75;
          font-size: unit(14px);
        }

        &:nth-child(2) {
          font-size: unit(20px);
          font-weight: 400;
          opacity: 0.9;
        }
      }
    }

    .profileImg {
      width: 40px;
      height: 40px;
      background: blue;
      border-radius: 50%;

      .profileImage {
        width: 100%;
        height: 100%;
      }
    }
  }
}
