@import '../../variable';

.sidebar {
  height: 100%;

  .logoMainContainer {
    cursor: pointer;
    padding: 0 unit(16px);
    height: $headerHeight;
    background: $black100;
  }

  .linkContainer {
    margin: 0;
  }

  .footerMainContainer {
    margin-top: auto;

    .flagContainer {
      padding: 0 unit(32px) unit(8px);

      >div {
          flex: 0 0 40px;
          max-width: 40px;
      
          .imgBtn {
            background: transparent;
            border: 0;
            display: block;
            padding: 0;
            margin: 0;
            width: 100%;
      
            .flagImg {
              width: 100%;
              height: auto;
              display: block;
            }
          }
        }
    }

    .footerContainer {
      background: $black100;
      padding: unit(16px);
    }
  }
}
