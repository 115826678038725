@import '../../../../variable';

.dropdownColumn {
  .dropdownContainer {

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}

.userColumn {
  margin-top: 32px;

  .userMainContainer {
    .userContainer {
      margin-bottom: 16px;
    }

    .addUserContainer {
      margin-bottom: 64px;
    }
  }
}

@media (min-width: $width992) {
  .dropdownColumn {
    flex: 0 0 30%;
    max-width: 30%;

    .dropdownContainer {
      .dropdownLabel {
        flex: 0 0 40%;
      }

      .dropdownDiv {
        flex: 0 0 50%;
      }
    }
  }

  .userColumn {
    margin-top: 0;
    flex: 0 0 70%;
    max-width: 70%;
    padding-left: 32px;

    .dropdownLabel {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .userMainContainer {
      flex: 0 0 80%;
      max-width: 80%;

      .userContainer,
      .addUserContainer {
        margin-bottom: 0;
        width: 274px;
        margin-right: 16px;
        margin-bottom: 16px;
      }
    }
  }
}