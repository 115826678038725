@import '../../variable';

.invoicingCardContainer {
  background: $black75;
  border-radius: 16px;
  padding: unit(16px) 0;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .cardContent {
    >div {
      flex: 0 0 50%;
      padding: 0 16px;

      .contentTitle {
        font-size: unit(14px);
        color: $lightGray100;
        padding-bottom: 16px;
      }

      .contentData {
        padding-bottom: 16px;
        font-size: unit(18px);
        color: $lightGray100;
        font-weight: 400;
      }
    }

    .taskContainer {
      border-bottom: 1px solid $black100;
      flex: 0 0 100%;
    }

    .hourContainer {
      flex: 0 0 100%;
      padding-top: 16px;
    }
  }

  .contentBtnContainer {
    padding: 0 unit(16px);

    .menuIconContainer {
      z-index: 100;
      position: absolute;
      top: 4px;
      right: 12px;
      width: 20px;

      .menuIcon {
        fill: $lightBlue50;
      }
    }
  }

}

@media (min-width: $width992) {
.invoicingCardContainer {
    padding: unit(16px);

    .cardContent {
      width: 85%;
      >div {
        flex: 0 0 16%;
        padding: 0;

        .contentTitle {
          // font-size: unit(14px);
          // color: $lightGray100;
          // padding-bottom: 16px;
        }

        .contentData {
          // padding-bottom: 16px;
          // font-size: unit(18px);
          // color: $lightGray100;
          // font-weight: 400;
        }
      }

      .taskContainer {
        border-bottom: none;
        flex: 0 0 20%;
      }

      .hourContainer {
        flex: 0 0 16%;
        padding-top: 0;
      }
    }

    .contentBtnContainer {
      padding: 0;
      width: 15%;
      padding-right: unit(36px);

      .menuIconContainer {
        top: 8px;
        right: 16px;
        width: 32px;
      }
    }
  }
}