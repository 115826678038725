@import '../../variable';

.accordionBody {
  padding: unit(16px);

  .CardColumn {

    &:nth-child(1),
    &:nth-child(2) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 24px;
    }

    &:nth-child(3) {
      flex: 0 0 60%;
      max-width: 60%;
    }

    &:nth-child(4) {
      flex: 0 0 35%;
      max-width: 35%;
    }

    .columnTitle {
      font-weight: 400;
      font-size: unit(16px);
    }

    .hoverLink:hover {
      cursor: pointer;
    }

    p {
      font-size: unit(14px);

      &:first-child {
        margin-bottom: 8px;
      }

      span {
        color: $gray75;
        margin-right: 8px;
      }
    }

    .projectStatus {
      color: $green100;
      font-size: unit(20px);
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}

@media (min-width: $width992) {
  .accordionBody {
    padding: unit(16px) unit(200px) unit(16px) unit(16px);

    .CardColumn {

      &:nth-child(1),
      &:nth-child(2) {
        flex: 0 0 30%;
        padding-right: 16px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        flex: 0 0 20%;
        max-width: auto;
        margin-bottom: 0;
      }

      &:nth-child(4) {
        text-align: center;
      }
    }
  }
}