@import '../../../../variable';

.workerMainContainer  {
  .workerTitle {
    color: $lightGray100;
    font-weight: 500;
    text-align: center;
    margin-bottom: 16px;
  }

  .messagesBg {
    background: $green100;
    color: $black50;
    border-radius: 16px;
    padding: unit(32px) unit(16px);
    font-size: unit(18px);
    font-weight: 500;
  }

  > div {
    margin-bottom: 16px;
  }

  .taskCardContainer  {
    > div:not(:last-child)  {
      margin-bottom: 16px;
    }

    .checkBoxMainContainer {
      margin: 0;

      p {
        color: $lightGray100;
          font-weight: 500;
          text-align: center;
          margin-bottom: 16px;
      }

      .labelText  {
        font-size: unit(12px);
        font-weight: 500;
        text-transform: uppercase;
      }

      > div:nth-child(2) {
        margin-bottom: 16px;
      }

      .breakContainer {
        margin: 16px auto 0;
        width: 75%;
      }
    }
  }
  .feedbackContainer  {
    margin-bottom: 0;
    .workerTitle:first-child  {
      margin-top: 32px;
      margin-bottom: 0;
    }

    .inputField {
      font-size: unit(12px);
      font-weight: 400;
      min-height: 115px;
    }

    .workerBtnContainer {
      margin-top: 16px;
    }
  }
  .workerDec  {
    color: $white;
    text-align: center;
    font-weight: 300;
  }
}