@import '../../variable';

.FamilyContainer {
  .label {
    color: $lightGray100;
    font-weight: 400;
    margin-bottom: 16px;
    font-style: italic;
  }

  .formContainer {
    .label {
      font-style: normal;
      margin-bottom: 8px;
    }

    .selectMainRadio {
      margin-bottom: 16px;

      .flexColumn {
        .selectRadio {
          color: $black50;
          font-weight: 400;

          >div {
            flex: 0 0 60%;
            max-width: 60%;
            border-radius: 5px;
            background: $lightGray100;

            &:nth-child(1) {
              flex: 0 0 35%;
              max-width: 35%;
            }

            &:nth-child(3) {
              margin-top: 16px;
            }
          }
        }
      }
    }

    .inputDiv {
      margin-bottom: 16px;

      .flexColumn {
        >div {
          &:not(:last-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    .binaryContainer {
      margin-bottom: 16px;

      .flexColumn {
        color: $black50;
        font-weight: 400;

        >div {
          flex: 0 0 48%;
          max-width: 48%;
          border-radius: 5px;
          background: $lightGray100;

          &:nth-child(2),
          &:nth-child(3) {
            margin-bottom: 16px;
          }

        }

      }
    }
  }
}

@media (min-width: $width992) {
  .FamilyContainer {
    .label {
      margin-bottom: 32px;
    }

    .formContainer {
      .label {
        margin-bottom: 16px;
      }

      .selectMainRadio,
      .inputDiv,
      .binaryContainer {
        .label {
          flex: 0 0 10%;
        }

        .flexColumn {
          flex: 0 0 65%;
        }
      }

      .selectMainRadio {
        margin-bottom: 32px;


        .flexColumn {

          .selectRadio {

            >div {
              flex: 0 0 31.66%;
              max-width: 31.66%;

              &:nth-child(1) {
                flex: 0 0 31.66%;
                max-width: 31.66%;
              }

              &:nth-child(1),
              &:nth-child(2) {
                margin-right: 2.5%;
              }

              &:nth-child(3) {
                margin-top: 0;
              }
            }
          }
        }
      }

      .inputDiv {
        .flexColumn {
          .label {
            flex: 0 0 100%;
          }

          >div {
            &:not(:last-child) {
              margin-bottom: 0;
            }

            &:nth-child(3) {
              flex: 0 0 31.66%;
              margin-right: 0;
            }

            &:nth-child(2) {
              flex: 0 0 31.66%;
              margin-right: 2.5%;
            }

            &:nth-child(4) {
              flex: 0 0 65.82%;
              margin-top: 16px;
            }

          }
        }
      }

      .binaryContainer {

        .flexColumn {

          .label {
            flex: 0 0 100%;
          }

          >div {
            flex: 0 0 31.66%;
            max-width: 31.66%;
            margin-right: 2.5%;
          }

        }
      }
    }
  }
}