@import "../../../variable";

.resetContainer {
  text-align: center;
  color: white;

  .loginBtnContainer  {
    text-decoration: none;
    display: block;
    .loginBtn {
        margin-top: 50px;
        background: $green100;
      }
  }
}