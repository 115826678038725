@import '../../variable';


.tabMainContainer {
  background-color: $black75;
  z-index: 999;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $mobTabHeight;

  >div {
    flex: 0 0 15%;
    max-width: 15%;
    text-align: center;
    cursor: pointer;
    padding: 0 unit(4px);

    .tabContainer {
      height: 100%;

      .tabIconContainer {
        width: unit(20px);
        margin: 0 auto;

        .tabIcon {
          fill: white;
          opacity: 65%;
        }
      }

      p {
        font-size: unit(10px);
      }
    }

    &.activeTab {
      background-color: rgba($orange, 0.1);

      .tabContainer {
        .tabIconContainer {
          .tabIcon {
            opacity: 1;
          }
        }
      }
    }
  }
}