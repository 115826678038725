@import '../../variable';

.userCardContainer {

	.userCard {
		background: $black50;
		color: $white;
		border: 0;
		border-radius: 16px;
		overflow: hidden;
		display: block;


		.userCardBody {
			padding: unit(16px);
			display: block;
			position: relative;

			.editBtnContainer	{
				position: absolute;
				top: 4px;
				right: 16px;
			}

			.userCardContent {
				padding-bottom: unit(16px);
				align-items: center;

				.userImgContainer {
					width: 80px;
					height: 80px;

					.userImg {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.userCardData {
					width: calc(100% - 80px);
					padding-left: unit(16px);

					.userDetailsContainer {
						margin-bottom: 16px;
						.userName {
							font-size: unit(20px);
							text-transform: uppercase;
							font-weight: 500;
							margin-bottom: 4px;
						}

						.userProfession,
						.userArea {
							font-weight: 300;
						}

						.userProfession {
							margin-bottom: 10px;
						}
					}

				}

			}

			.statusBtnContainer {
				padding-top: unit(16px);
				width: 100%;
				position: relative;

				>div {
					flex: 0 0 48%;
					max-width: 48%;

					&:not(:last-child) {
						margin-right: 16px;
					}
				}

				&::before {
					content: '';
					top: 0;
					left: -16px;
					right: -16px;
					position: absolute;
					height: 1px;
					background: $black100;
				}


			}

			&.assignedWorkersCard {

				.userCardContent {
					align-items: flex-start;

					.userCardData {
						.userTagContainer {
							// justify-content: flex-start;
							// >div {
							// 	margin-right: 4px;
							// 	margin-bottom: 4px;
							// }
						}

						.userDetailsContainer {
							.userName {
								text-transform: capitalize;
							}

						}
					}

				}

				.statusBtnContainer {
					&::before {
						display: none;
					}
				}
			}

			.BtnContainer {
				position: absolute;
				top: 16px;
				right: 16px;
				width: 20px;

				.delIcon {
					fill: $red;
				}

				.selectedIcon {
					fill: $green100;
				}
			}

		}


	}
}

@media (min-width: $width992) {
	.userCardContainer {

		.userCard {
			background: $black75;

			.userCardBody {

				.userCardContent {
					padding-bottom: unit(16px);
					align-items: flex-start;
					justify-content: center;

					.userImgContainer {
						width: 150px;
						height: 150px;
						margin-bottom: 16px;
					}

					.userCardData {
						width: 100%;
						padding-left: 0;
						text-align: center;


						.userDetailsContainer {
							.userName {
								margin-bottom: 16px;
							}

							.userProfession,
							.userArea {
								font-weight: 500;
							}

							.userProfession {
								margin-bottom: 16px;
							}
						}

						.userTagContainer	{
							justify-content: center;
						}
					}

				}

				.statusBtnContainer {

					>div {
						flex: 0 0 40%;
						max-width: 40%;
					}

					&::before {
						display: none;
					}
				}

				&.assignedWorkersCard {

					.userCardContent {
						align-items: flex-start;

						.userImgContainer {
							width: 135px;
							height: 135px;
						}

						.userCardData {
							width: calc(100% - 135px);
							padding-left: unit(16px);
							text-align: left;

							.userTagContainer {
								justify-content: start;
								// >div {
								// 	margin-right: 4px;
								// 	margin-bottom: 4px;
								// }
							}

							.userDetailsContainer {
								.userName {
									text-transform: capitalize;
								}

							}
						}

					}

					.statusBtnContainer {
						width: calc(100% - 135px);
						margin-left: auto;
						padding-left: unit(16px);

						>div {
							flex: 0 0 48%;
							max-width: 48%;
						}
					}
				}

				.delBtnContainer {
					width: 24px;

				}
			}
		}
	}
}