@import '../../../../variable';

.clientMainContainer {
  .clientHeader {
    background: $black75;
    border-radius: 16px;
    padding: unit(16px);
    margin-bottom: 16px;

    >div {
      &:nth-child(1) {
        flex: 0 0 32px;

        >svg {
          fill: white;
        }
      }

      &:nth-child(2) {
        flex: 0 0 35%;

        p {
          font-weight: 400;
          color: $white;

          &:nth-child(1) {
            font-size: unit(12px);
            color: $gray75;
            font-weight: 300;
          }
        }
      }

      &:nth-child(3) {
        flex: 0 0 50%;

        >div {
          flex: 0 0 48%;

          &:nth-child(1) {
            .phoneIconContainer {
              background-color: $green100;
              height: 100%;
              border-radius: 5px;
              text-decoration: none;
              font-weight: 500;
              color: white;

              .phoneIcon {
                width: 20px;
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }

  .periodContainer {
    margin-top: 16px;

    >div {
      p {
        &:nth-child(1) {
          color: $blue25;
          font-size: unit(14px);
        }
      }
    }
  }

  .decContainer {
    margin: 64px 0;

    p {
      font-size: unit(14px);

      &:nth-child(1) {
        font-size: unit(18px);
        font-weight: 400;
        margin-bottom: 8px;
      }
    }
  }

  .feedContainer {
    background: $black75;
    padding: unit(16px);
    border-radius: 16px;

    >div {
      p {
        font-weight: 400;
        margin-bottom: 16px;
        color: $lightGray100;
      }

      &:nth-child(1) {
        margin-bottom: 32px;
      }

      .dayText {
        font-size: unit(14px);
        color: $gray75;
      }
    }
  }
}