@import "../../variable";
.sidebarLink {
    cursor: pointer;
    padding-bottom: unit(24px);
    text-decoration: none;
    padding: unit(16px);
    color: $white;
    font-size: unit(14px);

    &:hover,&.active   {
        background: rgba($orange,0.1);
        color: $white;
    }
    .svgIcon {
        fill: $white;
    }
    .svgContainer {
        width: 20px;
        height: auto;
        display: block;
        margin-right: 16px;
    }
}

.active{
    background: rgb($orange, 0.1);
    color: $white;
}
