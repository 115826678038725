@import "../../variable";

.dropZoneContainer {
  background: $white;
  text-align: center;
  border-radius: 5px;
  border: 3px dashed $gray100;
  padding: unit(16px);

  .dropZoneText {
    font-size: unit(22px);
    font-weight: 400;
    color: $blue50;
  }

  p {
    color: $gray75;
    margin-bottom: 16px;
  }

  .cloudImgContainer {
    width: 90px;
    margin: 0 auto 16px;
  }

  .chooseFile {
    margin: 10px auto 0;
    width: 80%;
    background: $purple100;
    color: $white;
    padding: unit(8px);
    border-radius: 5px;
    font-weight: 400;
    font-size: unit(18px);
  }
}