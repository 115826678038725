@import '../../../../variable';

.settingTitle {
  font-weight: 400;
  font-size: unit(14px);
  color: $lightGray100;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.projectSettingsContent {

  .dropdownBtnContainer {
    background: $lightGray100;
    padding: unit(16px);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top: 1px solid $lightGray25;
  }

}

@media (min-width: $width992) {

  .textRight {
    text-align: right;
  }

  .settingTitle {
    font-size: unit(16px);
    margin-bottom: 32px;
  }

  .workTypeMainDiv {
    margin-left: 0;
    position: relative;
  }
}