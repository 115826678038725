@import '../../../../variable';

.workerListContainer {
	.headerContainer {
		margin-bottom: 16px;

		>div {
			&:nth-child(1) {
				flex: 0 0 50%;
				max-width: 50%;
			}

			&:nth-child(2) {
				flex: 0 0 45%;
				max-width: 45%;
			}
		}
	}

	.cardListContainer {
		>div {
			&:not(:last-child) {
				margin-bottom: 16px;
			}
		}
	}
}

@media (min-width: $width992) {
	.workerListContainer {
		.headerContainer {
			flex: 0 0 50%;
			max-width: 50%;
			margin-bottom: 16px;

			>div {
				&:nth-child(1) {
					flex: 0 0 200px;
					max-width: 200px;
					margin-right: 16px;
				}

				&:nth-child(2) {
					flex: 0 0 160px;
					max-width: 160px;
				}
			}
		}

		.cardListContainer {
			margin: 0;
			display: grid;
			grid-gap: 16px;
			grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

			>div {
				min-height: 350px;

				&:not(:last-child) {
					margin-bottom: 0;
				}

				>div {
					height: 100%;

					>div {
						height: 100%;

						>div {
							height: 100%;

							>div {
								&:nth-child(2) {
									>div {
										&:nth-child(2) {
											margin-bottom: 32px;
										}
									}
								}

								&:last-child {
									position: absolute !important;
									left: 0;
									bottom: 16px;
									width: 100%;
								}
							}
						}
					}
				}
			}
		}
	}
}