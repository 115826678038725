@import '../../variable';

.attachmentContainer {
  .attachmentContent {
    color: $lightGray100;
    padding: unit(16px);
    padding-bottom: 0;
  }
}

@media (min-width: $width992) {
  .attachmentContainer {
    .attachmentContent {
      padding: unit(32px) 0 0 unit(36px);
    }
  }
}