@import '../../variable';

.menuContainer {
	height: 100%;

	.menuItemContainer {
		.menuItem {
			cursor: pointer;
			padding: unit(16px);
			border-bottom: 2px solid $black100;

			&:first-child {
				border-top: 2px solid $black100;
			}

			>div {
				width: 20px;
				margin-right: 16px;
			}

			p {
				font-weight: 400;
			}
		}
	}

	.menuBottomContainer {
		margin-top: auto;

		.menuImgContainer {
			border-top: 2px solid $black100;
			padding: unit(16px);

			>div {
				width: 20%;

				.imgBtn {
					background: transparent;
					border: 0;
					display: block;
					padding: 0;
					margin: 0;
					width: 100%;

					.flagImg {
						width: 100%;
						height: auto;
						display: block;
					}
				}
			}
		}

		.logBtnContainer {
			cursor: pointer;
			background: $orange;
			padding: unit(8px) unit(16px);

			p {
				font-size: unit(19px);
				font-weight: 400;
			}

			>div {
				width: 25px;
				margin-right: 16px;
			}
		}
	}
}