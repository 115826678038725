@import '../../variable';

.companyDetailsContainer {
  background: $green100;
  border-radius: 16px;
  color: $black50;
  font-weight: 500;
  padding: unit(16px);

  .companyTitle {
    font-size: unit(14px);
    margin-bottom: 10px;
  }

  

  .contentContainer {
    margin-bottom: 12px;

    >div {
      width: 20px;
    }

    p {
      font-size: unit(9px);
      margin-left: 16px;
    }

    .companyTitle {
      font-size: unit(14px);
      margin: 32px 0 0;
    }

    .companyDec {
      margin-left: 0;
      font-size: unit(12px);
      font-weight: 400;
    }
  }
}