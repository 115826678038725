@import '../../variable';

.timeSheet {
  .sheetTitleContainer {
    font-size: unit(18px);
    font-weight: 500;
    padding: unit(16px);
    padding-bottom: 0;

    p {
      margin-right: 8px;
    }

    .noTimeLog {
      margin-left: 16px;
      white-space: nowrap;
      background: $black50;
      padding: unit(4px) unit(12px);
      border-radius: 8px;
    }
  }

  .tableContainer {
    width: 100%;

    .sheetHeaderContainer {
      border-bottom: 1px solid $black100;

      td {
        div {
          padding: unit(8px) unit(16px);
          min-width: 180px;

          p {
            white-space: nowrap;
            font-size: unit(14px);
            color: $lightGray100;
          }
        }
      }
    }

    .sheetDataContainer {
      border-bottom: 1px solid $black100;

      td {
        > div {
          padding: unit(8px) unit(16px);
          min-width: 180px;

          p {
            white-space: nowrap;
            font-size: unit(18px);
            color: $lightGray100;
            font-weight: 500;
          }
        }
      }
    }
    .disabled {
      opacity: 0.5;
    }
  }
}

@media (min-width: $width992) {
  .timeSheet {
    .sheetTitleContainer {
      color: $white;
    }

    .tableContainer {
      .sheetHeaderContainer {
        td {
          div {
            padding: unit(8px) unit(8px);
            min-width: auto;

            p {
              white-space: wrap;
            }
          }
        }
      }

      .sheetDataContainer {
        td {
          > div {
            padding: unit(8px) unit(8px);
            min-width: auto;

            p {
              white-space: wrap;
            }
          }
        }
      }
    }
  }
}
