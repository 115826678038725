@import '../../variable';


.itemsMainContainer {

  .itemsTitle {
    font-size: unit(18px);
    font-weight: 500;
  }

  .itemsTitleContainer {
    margin-bottom: 16px;
    padding: 0 unit(16px);
  }

  .serviceItemContainer {
    .serviceTitle {
      padding: 0 unit(16px);
      font-size: unit(14px);
      font-weight: 400;
      margin-bottom: 16px;
    }

    .flexMainContainer {
      padding: unit(8px) unit(16px);
      border-top: 1px solid $black100;
      border-bottom: 1px solid $black100;
      margin-bottom: 16px;

      .flexContainer {
        &:first-child {
          width: 100%;

          .flexTitle {
            margin-bottom: 12px;
          }
        }

        p {
          color: $gray75;
          font-size: unit(14px);
        }

        .flexTitle {
          font-size: unit(18px);
          font-weight: 500;
          color: $white;
        }

      }
    }
  }

  .inputMainContainer {
    padding: 0 unit(16px);
    margin-bottom: 16px;

    .inputFieldContainer {
      flex: 0 0 25%;
      max-width: 25%;

      &:first-child {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    .iconMainContainer {
      .iconContainer {
        width: 32px;
        margin-right: 16px;

        .icon {
          fill: white;
        }
      }
    }
  }

  .BtnContainer {
    padding: 0 unit(16px);

    .addBtn {
      font-size: unit(14px);
      font-weight: 400;
      cursor: pointer;

      .iconContainer {
        margin-right: 12px;
        width: 28px;
        height: 28px;
        line-height: 24px;
        border: 1px solid white;
        border-radius: 50%;
        padding: 2px;

        .icon {
          fill: white;
          margin-top: -3px;
        }
      }
    }

    .saveBtnContainer {
      .saveBtn {
        padding-left: 12px;
        padding-right: 12px;

        .iconContainer {
          width: 20px;
          margin-right: 12px;
        }
      }
    }
  }

  .totalContainer {
    margin-top: 100px;
    padding: 0 unit(16px);

    .flexDiv {

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      >div {
        p {
          font-size: unit(18px);
        }
      }

      &:last-child {
        >div {
          p {
            font-size: unit(24px);
            font-weight: 500;
            color: $orange;
          }
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .itemsMainContainer {
    background: $white;
    padding: unit(16px) 0;
    border-radius: 16px;

    .itemsTitle {
      color: $black50;
    }


    .serviceItemContainer {
      .serviceTitle {
        font-weight: 500;
        color: $black50;
        margin-bottom: 0;
      }

      .flexMainContainer {
        border: none;

        // margin-bottom: 0;
        .flexContainer {
          &:first-child {
            width: auto;

            .flexTitle {
              // margin-bottom: 12px;
              color: $black50;
            }
          }

          p {
            color: $black50;
            font-weight: 400;
          }

          .flexTitle {
            color: $black50;
          }

        }
      }

      .additionalContainer {
        .flexContainer {
          flex: 0 0 18%;
          max-width: 18%;

          &:first-child {
            flex: 0 0 40%;
            max-width: 40%;
          }

          // &:not(:last-child) {
          //   margin-bottom: 16px;
          // }
        }
      }
    }

    .inputMainContainer {
      .inputFieldContainer {
        flex: 0 0 18%;
        max-width: 18%;

        &:first-child {
          flex: 0 0 39%;
          max-width: 39%;
        }

        // &:not(:last-child) {
        //   margin-bottom: 16px;
        // }
      }

      .iconMainContainer {
        .iconContainer {
          .icon {
            fill: $black50;
            ;
          }
        }
      }
    }

    .BtnContainer {

      .addBtn {
        font-weight: 500;
        color: $black50;

        .iconContainer {
          border: 1px solid $black50;

          .icon {
            fill: $black50;
          }
        }
      }
    }

    .totalContainer {
      .flexDiv {
        >div {

          &:nth-child(1) {
            min-width: 80px;
            text-align: left;
          }

          &:nth-child(2) {
            min-width: 120px;
            text-align: right;
          }
        }
      }
    }
  }
}