@import '../../variable';

.tabMainContainer {
  position: relative;
}

.leftArrowContainer,
.rightArrowContainer {
  padding: 0 16px;
  height: 51px;
  top: 0;
  left: 0%;
  position: absolute;
  z-index: 100;
  background: transparent linear-gradient(91deg, #000000 0%, #000000 51%, #00000000 100%) 0% 0% no-repeat padding-box;

  .leftRightArrow {
    width: 24px;
    height: 100%;
    fill: white;
  }
}

.rightArrowContainer {
  left: auto;
  right: 0;
  background: transparent linear-gradient(269deg, #000000 0%, #000000 51%, #20202000 100%) 0% 0% no-repeat padding-box;
}


@media (min-width: $width992) {

  .leftArrowContainer,
  .rightArrowContainer {
    height: 57px;
  }

}

@media (min-width: $width1920) {

  .leftArrowContainer,
  .rightArrowContainer {
    display: none;
  }
}