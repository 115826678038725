@import "../../variable";

.footer {
    .svgContainer {
        text-align: center;
        font-size: 14px;
        color: $white;

        &:not(:last-child) {
            margin-right: 10px;
        }

        .svgIcon {
            width: 20px;
            margin: 0 auto 10px;
            display: block;
            color: $white;
        }
    }
}