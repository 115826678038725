@import '~bootstrap/scss/bootstrap';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import './variable';
@import 'react-big-calendar/lib/sass/styles';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker.css';

body {
  font-family: 'Roboto', sans-serif;
  font-size: unit(16px);
  background: $black100;
  color: $white;
  font-weight: 300;
}

p {
  margin: 0;
}

a {
  color: inherit;
}

// font classes

.fontSegoe {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.fontProximaNova {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.fontArial {
  font-family: Arial, Helvetica, sans-serif;
}

// page title & dec

.pageTitle {
  font-size: unit(18px);
  font-weight: 500;
}

.pageDec {
  font-size: unit(12px);
}

.inputField {
  background: $lightGray100;
  box-shadow: none;
  outline: 0;
  color: $black50;
  font-weight: 400;
  border-color: transparent;
  width: 100%;
  border-radius: 5px;
  padding: unit(8px);

  &:focus {
    border-color: transparent;
    box-shadow: none;
    color: $black50;
    outline: none;
  }
}

/* start of custom radio  */
.radioContainer {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 8px 48px 8px;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.radioContainer input[type='radio']:checked~.radioIcon::after {
  display: block;
}

.radioContainer .radioIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 40px;
  background: #6a707e1a;
}

.radioContainer .radioIcon::after,
.radioContainer .radioIcon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  background: $black50;
  transform: translate(-50%, -50%);
  display: none;
}

.radioContainer .radioIcon::before {
  display: block;
  background: white;
}

/* end of custom radio  */

/* start of custom checkbox  */
.checkboxContainer {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 28px;
  margin: 0 0 10px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxContainer input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkboxContainer input[type='checkbox']:checked~.checkIcon::after {
  display: block;
}

.checkboxContainer .checkIcon {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid white;
  background: white;
  border-radius: 2px;
}

.checkboxContainer .checkIcon::after {
  content: '';
  position: absolute;
  left: 4px;
  top: 0px;
  width: 7px;
  height: 11px;
  border: solid $black50;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

/* end of custom checkbox  */

// overwrite calendar css

.projectCalendar {
  background: $black75;
  height: 400px !important;
  border-radius: 16px;
  overflow: hidden;

  .rbc-month-view {
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: black;

    .rbc-month-header {
      .rbc-header {
        padding: 8px 0;
        color: $white;
        // font-size: 26px;
        border-bottom: none;
      }

      .rbc-header+.rbc-header {
        border-left: 1px solid #000;
      }
    }

    .rbc-month-row {
      border-top: 1px solid black;

      .rbc-row-bg {
        .rbc-off-range-bg {
          background: $black75;
        }

        .rbc-day-bg {
          border-color: #000;
        }

        .rbc-today {
          background: transparent;
          border-radius: 0;
        }
      }

      .rbc-row-content {
        position: relative;
        height: 100%;

        .rbc-row {
          &:nth-child(1) {
            height: 100%;
          }

          .rbc-date-cell {
            text-align: center;
            padding: 8px;
          }

          .rbc-off-range {
            color: #4e4e4e;
          }

          .rbc-current {
            background: #000000;
          }
        }

        .rbc-row {

          &:nth-child(2),
          &:nth-child(3) {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;

            .rbc-row-segment {
              button {
                margin-left: 15px;
                font-size: 10px;
                color: #d56730;
                background: transparent;
              }
            }
          }
        }
      }
    }
  }
}

// overwrite tabs css

.menuTabs {
  border: 0;
  flex-wrap: nowrap;
  position: relative;
  // padding-left: 58px;
  // padding-right: 58px;
  overflow-y: hidden;
  overflow-x: auto;

  .nav-item {
    white-space: nowrap;

    .nav-link {
      display: block;
      background: $black100;
      color: $lightGray100;
      padding: unit(16px) unit(32px);
      border: 1px solid transparent;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 0;
      font-size: unit(12px);
      font-weight: 400;

      &.active {
        background: $green100;
        color: $black75;
        border-color: transparent;
      }

      &:hover {
        border-color: transparent;
      }

      &.disabledTab {
        opacity: 0.4;
        background-color: $gray75;
      }
    }
  }
}

.menuTabs::-webkit-scrollbar {
  width: 0;
}

.menuTabs {
  overflow: -moz-scrollbars-none;
}

.menuTabs {
  -ms-overflow-style: none;
}

// overwrite dropdown css

.multiSelectDropDown {
  .multiSelect__indicators {
    background: #6a707e1a;
    align-items: flex-start;
    align-self: flex-start;

    .css-tj5bde-Svg {
      fill: $black100;
    }
  }

  .multiSelect__control {
    background: $lightGray100;
    border: 0;
    box-shadow: none;

    &:hover {
      border-color: transparent;
    }
  }

  .multiSelect__control--menu-is-open {
    background: $lightGray100;
    box-shadow: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .multiSelect__menu-list {
    padding: 0;
    min-height: auto;
  }

  .multiSelect__menu {
    background: $lightGray25;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .multiSelect__option {
    font-weight: 400;
    padding: 8px 16px;
    color: $black50;
    background: $lightGray25;

    &:hover,
    &:active {
      background: $gray75;
      color: white;
    }
  }

  .css-1pf0xdc-control {
    .multiSelect__value-container {
      background: transparent;

      .multiSelect__multi-value {
        margin-bottom: 8px;
      }

      .multiSelect__placeholder {
        color: rgba($black50 , 0.6);
        font-weight: 300;
      }
    }
  }
}

// overwrite pagination css

.pagination {
  margin: 16px 0;

  .page-item {
    .page-link {
      background: $lightGray100;
      color: $black100;
      border-color: $lightGray100;

      &:focus {
        box-shadow: none;
      }
    }

    &:first-child,
    &:last-child {
      .page-link {
        background: white;
        border-color: white;
        color: $black100;
      }
    }

    &.active {
      .page-link {
        background: $orange;
        border-color: $orange;
        color: white;
      }
    }
  }
}

.appMainContainer {
  height: 100vh;

  .mainContentContainer {
    .ContentContainer {
      padding: $mobHeaderHeight + 16px unit(16px) $mobTabHeight + 16px;
    }
  }
}

.tabsScrollMainContainer {
  margin-top: 16px;
  border-radius: 16px;
  background: $black75;
  overflow: hidden;
  border: 1px solid $black50;

  .scrollContainer {
    padding: unit(16px);

    .dropdownLabel,
    .cardLabel {
      color: $lightGray100;
      margin-bottom: 16px;
      font-weight: 400;
    }

    .dropdownContainer {
      margin-bottom: 16px;

      .workTypeMainDiv {
        .workTypeDiv {
          background: $lightGray100;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          padding: unit(16px);
        }
      }
    }

    .inputMainContainer {
      .inputContainer {
        margin-bottom: 16px;

        .inputDiv {
          width: 45%;
        }

        .inputDash {
          width: 3%;
          text-align: center;
          font-weight: 500;
        }
      }
    }

    &.uploadAssignedMainContainer {
      .uploadAssignedContainer {
        >div {
          margin-bottom: 16px;
        }
      }
    }

    .btnContainer {
      margin-top: 64px;
    }
  }
}

@media (min-width: $width992) {
  body {
    background: $lightGray100;
    color: $black50;
  }

  .pageTitle {
    font-size: unit(28px);
  }

  .pageDec {
    font-size: unit(22px);
  }

  // overwrite calendar css

  .projectCalendar {
    background: $lightGray75;
    height: 465px !important;
    border-radius: 0;
    overflow: auto;

    .rbc-month-view {
      border-left-color: #ddd;
      border-bottom-color: #ddd;
      border-right-color: #ddd;
      border-top-color: #ddd;

      .rbc-month-header {
        .rbc-header {
          padding: 16px;
          color: black;
          // font-size: 26px;
          // border-bottom: 1px solid #ddd;
        }

        .rbc-header+.rbc-header {
          border-left: 1px solid #ddd;
        }
      }

      .rbc-month-row {
        border-top: 1px solid #ddd;

        .rbc-row-bg {
          .rbc-off-range-bg {
            background: $lightGray75;
          }

          .rbc-day-bg {
            border-color: #ddd;
          }

          .rbc-today {
            background: transparent;
            border-radius: 0;
          }
        }

        .rbc-row-content {
          .rbc-row {
            .rbc-date-cell {
              text-align: center;
              padding: 16px;
            }

            .rbc-off-range {
              color: #aaaaaa;
            }

            .rbc-current {
              background: #ffffff;
              border-radius: 10px;
            }
          }

          .rbc-row {

            &:nth-child(2),
            &:nth-child(3) {
              bottom: 3px;

              .rbc-row-segment {
                button {
                  margin-left: 30px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  // overwrite tabs css

  .menuTabs {
    .nav-item {
      .nav-link {
        font-size: unit(16px);
        font-weight: 500;
      }
    }
  }

  // overwrite dropdown css

  .multiSelectDropDown {
    .multiSelect__menu-list {
      padding-right: 32px;
    }
  }

  // overwrite pagination css

  .pagination {
    .page-item {
      .page-link {
        background: $black50;
        color: white;
        border-color: $black50;
      }

      &:first-child,
      &:last-child {
        .page-link {
          background: $black100;
          border-color: $black100;
          color: white;
        }
      }
    }
  }

  .tabsScrollMainContainer {
    border: 0;
    // width: 96%;
    background: $black50;

    .scrollContainer {
      height: calc(100vh - 230px);
      overflow-y: auto;

      .dropdownLabel {
        margin-bottom: 0;
      }

      .dropdownContainer {
        margin-bottom: 0;

        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 30%;
          max-width: 30%;
          padding-right: 16px;
        }

        &:nth-child(3) {
          flex: 0 0 40%;
          max-width: 40%;

          // .dropdownLabel {
          //   margin-right: 16px;
          // }
        }

        .dropdownLabel {
          flex: 0 0 35%;
          max-width: 35%;
        }

        .dropdownDiv {
          flex: 0 0 60%;
          max-width: 60%;
        }

        .workTypeMainDiv {
          flex: 0 0 auto;
          padding-left: 16px;
        }

        .workTypePositionDiv {
          flex: 0 0 65%;
        }
      }

      .inputMainContainer {
        margin-top: 16px;

        .inputContainer {
          flex: 0 0 30%;
          max-width: 30%;
          padding-right: 16px;

          .dropdownLabel {
            flex: 0 0 35%;
            max-width: 35%;
          }

          .inputDiv {
            flex: 0 0 60%;
            max-width: 60%;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            flex: 0 0 35%;
            max-width: 35%;

            .dropdownLabel {
              flex: 0 0 34%;
              max-width: 34%;
            }

            .dashedInputContainer {
              flex: 0 0 66%;
              max-width: 66%;

              .inputDiv {
                flex: 0 0 47%;
                max-width: 47%;
              }

              .inputDash {
                width: 3%;
                color: white;
              }
            }
          }

          .inputSymbol {
            color: white;
          }
        }
      }

      &.uploadAssignedMainContainer {
        .cardLabel {
          flex: 0 0 10%;
          max-width: 10%;
          margin-bottom: 0;
        }

        .uploadAssignedContainer {
          flex: 0 0 90%;
          max-width: 90%;
          // position: relative;
          // padding-left: 290px;
          display: grid;
          grid-template-columns: repeat(auto-fit, 275px);
          width: 350px;
          border: 1px solid;
          overflow: auto;
          // resize: horizontal;

          .searchDiv {
            // background: red;
            grid-area: 1/1/span 200;
            // position: absolute;
            //   top: 0;
            //   left: 0;
            //   width: 275px;
          }

          >div {
            // flex: 0 0 275px;
            // max-width: 275px;
            margin-right: 16px;
            // &:not(:last-child) {
            //   margin-right: 16px;
            // }
          }
        }
      }

      .btnContainer {
        margin-top: auto;
        padding-left: calc(11% + 5px);
        padding-bottom: unit(16px);
        width: 40%;
      }
    }
  }

  .appMainContainer {
    .sidebarContainer {
      flex: 0 0 230px;
      max-width: 230px;
      background: $black50;
    }

    .mainContentContainer {
      width: calc(100% - 230px);

      .headerContainer {
        padding: 0 unit(16px);
        height: $headerHeight;
        background: $white;
      }

      .ContentContainer {
        height: calc(100vh - #{$headerHeight});
        overflow-y: auto;
        padding: unit(16px);
        padding-right: unit(72px);
      }
    }
  }
}