@import '../../variable';

.feedbackMainContainer {
  p {
    font-size: unit(14px);
    margin-bottom: 16px;
    color: $lightGray100;
  }

  >div {
    &:nth-child(1) {
      margin-bottom: 48px;
    }

    &:nth-child(2) {
      .btnContainer {
        margin-top: 16px;
      }
    }
  }
}

@media (min-width: $width992) {
  .feedbackMainContainer {
    >div {
      &:nth-child(1) {
        flex: 0 0 40%;
        max-width: 40%;
      }

      &:nth-child(2) {
        flex: 0 0 30%;
        max-width: 30%;
        margin-right: 70px;
      }
    }
  }
}