@import "../../../variable";

.downBtnColumn {
    width: unit(28px);
    background: transparent;
    border: 0;
    box-shadow: none;
    outline: 0;
    svg {
        fill: white;
    }
}