@import '../../variable';

.mobHeaderContainer {
  height: $mobHeaderHeight;
  background: $black75;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  .menuMainContainer {
    position: absolute;
    top: 100%;
    left: 0;
    background: $black75;
    width: 70%;
    height: calc(100vh - #{$mobTabHeight + $mobHeaderHeight });
  }

  .openCloseIcon {
    width: 50px;
    border-right: 1px solid $black100;
    padding: unit(8px);
    cursor: pointer;
  }

  .mobHeader {
    width: calc(100% - 50px);
    padding: 0 unit(16px);

    .profileContainer {

      .profileImgContainer {
        width: unit(30px);
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        .profileImg {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }

      >div {
        p {
          font-weight: 500;

          &:nth-child(1) {
            font-weight: 300;
            color: $gray75;
            font-size: unit(8px);
          }
        }
      }
    }

    .profileContent {
      margin-left: auto;

      .iconContainer {
        width: 20px;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }

      .searchContainer {
        .searchBar {
          margin-right: 16px;
        }
      }
    }
  }
}