@import '../../variable';

.actionMenuIcon {
  fill: $lightBlue50;
}
.actionMenuContainer  {
  padding: 0;
  min-width: auto;
  padding: 0;
  box-shadow: 0px 3px 6px #00000029;
  border: 0;
  background: transparent;
  margin-top: 5px;
  margin-right: -10px;
  // overflow: hidden;
  &::after  {
    content: '';
    z-index: 999;
    position: absolute;
    top: -16px;
    right: 11px;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    // border-radius: 5px;
    border-bottom-color: $lightBlue50;
    // background-color: red;
  }


  .actionMenuLink {
    color: white;
  border-radius: 8px;
    padding: unit(8px) unit(16px);
    background: $lightBlue50;
    font-size: unit(14px);
    font-weight: 500;
  }
}