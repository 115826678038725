@import "../../variable";

.companyFormContainer {
  padding: unit(16px);

  .companyDetailsContent {
    color: $white;

    .formLabel {
      margin: 0;
      margin-bottom: 8px;
    }

    .formInput {
      background: $lightGray100;
      box-shadow: none;
      padding: unit(8px) unit(16px);
      border-radius: 5px;
      border: 0;
      color: $black50;
      font: inherit;
      font-weight: 400;

      &:focus {
        box-shadow: none;
        outline: 0;
      }
    }

    .firstContainer {

      >div {
        width: 100%;
        margin-bottom: 16px;



        &:nth-child(2),
        &:nth-child(3) {
          flex: 0 0 45%;
          max-width: 45%;
        }
      }

    }

    .secondContainer {
      >div {
        margin-bottom: 16px;
      }
    }

    .zipCityContainer {
      margin-bottom: 16px;

      >div {

        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 45%;
          max-width: 45%;
        }

      }
    }

    .clientContainer {
      margin-bottom: 16px;
      
      .formLabel {
        flex: 0 0 100%;
        max-width: 100%;
      }
      
      >div {
        color: $black50;
        flex: 0 0 45%;
        max-width: 45%;
        background: $lightGray100;
        border-radius: 5px;
      }
    }

  }

  .btnContainer {
    padding: unit(32px) 0;

    >div {
      &:nth-child(1) {
        margin-bottom: 32px;
      }
    }
  }

}

@media (min-width: $width992) {
  .companyFormContainer {
    padding: unit(32px);
    height: 100%;

    .companyDetailsContent {
      width: 60%;

      .formLabel {
        margin-bottom: 0;
      }

      .firstContainer {

        >div {

          &:nth-child(1),
          &:nth-child(4) {
            flex: 0 0 55%;
            max-width: 55%;

            .formLabel {
              flex: 0 0 40%;
              max-width: 40%;
            }

            .formInput {
              flex: 0 0 60%;
              max-width: 60%;
            }
          }

          &:nth-child(2),
          &:nth-child(3) {
            flex: 0 0 40%;
            max-width: 40%;

            .formLabel {
              flex: 0 0 30%;
              max-width: 30%;
            }

            .formInput {
              flex: 0 0 70%;
              max-width: 70%;
            }
          }
        }

      }

      .secondContainer {
        >div {
          margin-bottom: 16px;

          &:nth-child(1) {
            flex: 0 0 22%;
            max-width: 22%;
          }

          &:nth-child(2) {
            flex: 0 0 78%;
            max-width: 78%;
          }
        }
      }

      .zipCityContainer {
        >div {

          &:nth-child(1),
          &:nth-child(2) {
            flex: auto;
            max-width: 100%;
          }

          &:nth-child(1) {
            .formLabel {
              flex: 0 0 44%;
              max-width: 44%;
            }
          }

          &:nth-child(2) {
            .formLabel {
              flex: 0 0 20%;
              max-width: 20%;
              text-align: center;
            }
          }
        }
      }

      .clientContainer {

        .formLabel {
          flex: 0 0 22%;
          max-width: 22%;
        }

        >div {
          flex: 0 0 25%;
          max-width: 25%;
          margin-right: 32px;
        }
      }

    }

    .btnContainer {
      margin-top: auto;
      padding: 0;
      padding-left: calc(12% + 15px);

      >div {
        flex: 0 0 33%;

        &:nth-child(1) {
          margin-bottom: 0;
          margin-right: 16px;
        }
      }
    }
  }
}