@import '../../variable';

.pageLoaderContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (min-width: $width992) {
  .pageLoaderContainer {
      width: 100%;
      height: 100%;
      position: static;
      top: auto;
      left:auto;
    }
}

.pageLoader {
}
