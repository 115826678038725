@import "../../../variable";

.formContainer {

  ::placeholder {
    color: white;
  }

  .inputContainer {
    margin-bottom: 32px;

    .inputField {
      background: $black100;
      box-shadow: none;
      outline: 0;
      border: 1px solid $white;
      border-radius: 5px;
      text-align: center;
      font-size: unit(14px);
      color: $white;
      padding: unit(10px) unit(16px);

      &:focus,
      &:active {
        background: $black100;
      }
    }
  }

  .loginBtn {
    margin-top: 50px;
    background: $green100;
  }

  .forgotPasswordContainer {
    margin-top: 16px;
    text-align: center;

    .forgotPasswordLink {
      color: $white;
      text-decoration: none;
      font-size: unit(14px);
    }
  }

  .flagContainer {
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);

    >div {
      flex: 0 0 25%;
      max-width: 25%;

      &:not(:last-child) {
        margin-right: 32px;
      }

      .imgBtn {
        background: transparent;
        border: 0;
        display: block;
        padding: 0;
        margin: 0;
        width: 100%;

        .flagImg {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .formContainer {


    .inputContainer {
      margin-bottom: 16px;
    }

    .loginBtn {
      margin-top: 0;
    }

    .flagContainer {
      margin-top: 32px;
      position: static;
      left: auto;
      bottom: auto;
      transform: translateX(0);

      >div {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }
  }
}