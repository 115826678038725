@import '../../variable';

.UploadMainContainer {
  width: 45%;
  margin: 0 auto;

  p {
    font-size: unit(12px);
    color: $lightGray100;
    font-weight: 500;
    margin-bottom: 16px;
  }

  .contentDec {
    text-transform: none;
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
  }

  .UploadContainer {
    border: 4px dashed $yellow100;
    padding: unit(16px) unit(16px) 0;
    text-align: center;

    .flexDiv {
      margin-top: 32px;

      >div {
        flex: 0 0 40%;

      }
    }

    .UploadedFileContainer {
      margin-bottom: 16px;

      >div {
        p {
          margin: 0;
        }

        &:nth-child(2) {
          width: 24px;
        }
      }
    }
  }
}