@import "../../variable";

.attachmentContainer {
  >div {
    &:nth-child(1) {
      .attachmentContent {
        color: $lightGray0;
        padding: unit(8px) 0;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1px;
          left: -16px;
          right: -16px;
          background: $black100;
        }

        &:first-child {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            height: 1px;
            left: -16px;
            right: -16px;
            background: $black100;
          }
        }
      }
    }

    &:nth-child(2) {
      margin-bottom: 32px;
    }
  }
}

@media (min-width: $width992) {
  .attachmentContainer {
    padding: unit(32px) unit(64px) 0 unit(24px);

    >div {
      &:nth-child(1) {
        flex: 0 0 23%;
        max-width: 23%;

        .attachmentContent {
          padding: unit(16px) 0;
          position: static;

          &::after {
            display: none;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }
        }
      }

      &:nth-child(2) {
        margin-bottom: 0;
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  }
}