@import '../../variable';

.contactContainer {

  .flexContainer:not(:nth-last-child(2)) {
    margin-bottom: 16px;
  }

  .flexContainer {
    .label {
      margin-bottom: 8px;
      color: $lightGray100;
      font-weight: 400;
    }

    .inputDiv {
      .cityZipContainer {
        flex: 0 0 47%;
        max-width: 47%;
      }
    }

  }

}

@media (min-width: $width992) {
  .contactContainer {

    .flexContainer:not(:nth-last-child(2)) {
      margin-bottom: 32px;
    }

    .flexContainer {
      .label {
        margin-bottom: 0;
        flex: 0 0 12%;
        max-width: 12%;
      }

      .inputDiv {
        flex: 0 0 40%;
        // max-width: 40%;
      }

      .inputDiv {
        .cityZipContainer {
          flex: 0 0 42%;
          max-width: 42%;
        }

        .cityZipContainer:nth-child(2) {
          flex: 0 0 48%;
          max-width: 48%;

          .label {
            flex: 0 0 20%;
            max-width: 20%;
          }

          .inputDiv {
            flex: 0 0 80%;
            max-width: 80%;
          }
        }
      }
    }

  }
}