@import '../../variable';
.tag {
  border-radius: 5px;
  font-size: unit(12px);
  font-weight: 600;
  padding: unit(4px) unit(12px);
  display: inline-block;
  margin-bottom: 16px;
  margin-right: 16px;
}

// you can use this tag where wee need white background

.whiteTag {
  background-color: $white;
  color: $black75;
}

.priceTag {
  background-color: $yellow100;
}

.specialTag {
  background-color: $orange;
  color: $white;
}

.selected {
  background-color: $orange;
  color: $black75;
}



