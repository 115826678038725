.searchBoxContainer {
  margin-bottom: 16px;
}
.tagScrollContainer {
  max-height: 250px;
  overflow-y: auto;
}

.tagItem {
  color: red;
  width: 60px;
  border: 1px solid #000;
  margin: 10px;
}
