@import '../../variable';

.timerContainer {
  p {
    color: $lightGray100;
    text-transform: uppercase;
    font-size: unit(12px);
    font-weight: 500;
    margin-bottom: 10px;
  }

  >div:first-child {
    margin-bottom: 32px;
  }

  .clockContainer {
    background: $white;
    border-radius: 5px;
    padding: unit(4px);
    width: 75%;

    >div {
      &:nth-child(1) {
        background: $green100;
        padding: unit(6px);
        border-radius: 10px;
        flex: 0 0 45px;
        max-width: 45px;
      }

      &:nth-child(2) {
        width: calc(100% - 65px);
        margin-left: 16px;
      }

      &.finishTime {
        background: $orange;
      }
    }

    .inputField {
      width: 100%;
      background: transparent;
      border: 0;
      text-align: center;
      font-size: unit(28px);
      font-weight: 500;
      color: $black50;
      padding: 0;
    }
  }
}