@import "../../variable";

.inputContainer {
  position: relative;

  .inputField {
    width: 100%;
    border: 0;
    outline: 0;
    background: $lightGray100;
    padding: unit(8px) unit(38px) unit(8px) unit(16px);
    border-radius: 50px;
  }

  .inputIconContainer {
    position: absolute;
    text-align: center;
    width: 18px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}