@import '../../variable';

.detailContainer {
  .label {
    margin-bottom: 16px;
    font-weight: 400;
  }

  .statusContainer {
    margin-top: 16px;

    > div {
      margin-bottom: 16px;
    }
  }

  .formContainer {
    .inputDiv {
      margin-bottom: 16px;

      .inputBtn {
        margin-top: 32px;
      }

    }

    .selectMainRadio {
      margin-bottom: 16px;

      .selectRadio {
        color: $black50;
        font-weight: 400;

        >div {
          flex: 0 0 48%;
          max-width: 48%;
          border-radius: 5px;
          background: $lightGray100;

          &:nth-child(3) {
            margin-top: 16px;
          }
        }
      }
    }

    .inviteContainer {
      .inputBtn {
        margin-top: 32px;
      }

    }
  }

  .uploadContainer {
    .imgContainer {
      margin-bottom: 16px;
      border-radius: 16px;
      overflow: hidden;
      border: 1px solid $gray100;
      height: 265px;
      text-align: center;

      .uploadImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .btnContainer {
      margin-top: 16px;
    }
  }
}

@media (min-width: $width992) {
  .detailContainer {
    .label {
      color: $lightGray100;
    }

    .statusContainer {
      flex: 0 0 30%;
      max-width: 30%;
      padding: 0 7%;
      margin: 0;
    }

    .formContainer {
      flex: 0 0 45%;
      max-width: 45%;

      .inputDiv {
        margin-bottom: 32px;
      }

      .inputDiv,
      .selectMainRadio {
        .label {
          flex: 0 0 30%;
          max-width: 30%;
          margin-bottom: 0;
        }

        >div {
          flex: 0 0 70%;
          max-width: 70%;
        }

      }

      .selectMainRadio {
        margin-bottom: 32px;

        .selectRadio {
          color: $black50;
          font-weight: 400;

          >div {
            flex: 0 0 30%;
            max-width: 30%;

            &:nth-child(1),
            &:nth-child(2) {
              margin-right: 5%;
            }

            &:nth-child(3) {
              margin-top: 0;
            }
          }
        }
      }

      .inviteContainer {
        >div:nth-child(1) {
          flex: 0 0 65%;
          max-width: 65%;
          margin-right: 5%;
        }

        .inputBtn {
          flex: 0 0 30%;
          max-width: 30%;
          margin-top: 0;
        }
      }
    }

    .uploadContainer {
      flex: 0 0 25%;
      max-width: 25%;

      .imgContainer {
        height: 360px;
      }
    }
  }
}