@import '../../variable';

.overviewCardContainer {
  background-color: $black50;
  border-radius: 16px;
  padding: unit(16px);
  height: 152px;

  .contentContainer {
    .overviewTitle {
      font-size: unit(12px);
      font-weight: 700;
      color: $lightBlue75;
      text-transform: uppercase;
      margin: 8px 0;
    }

    .overviewCount {
      color: $white;
      font-size: unit(32px);
      font-weight: 700;
    }

    .ChangeValue {
      color: $green100;
      font-weight: 500;

      .changeValueIcon {
        width: 20px;
        margin-right: 8px;
      }
    }

    .decreasedValue {
      color: $red;
    }
  }
}

@media (min-width: $width992) {
  .overviewCardContainer {
    width: 100%;
    position: relative;

    .contentContainer {
      flex: 0 0 60%;

      .overviewTitle {
        margin-top: 0;
      }

      .overviewCount {
        position: absolute;
        bottom: 16px;
        right: 32px;
        font-size: unit(48px);
      }
    }

    .graphContainer {
      flex: 0 0 40%;
    }
  }
}
