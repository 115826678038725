@import '../../variable';

.selectUserContainer  {
  .UserCardContainer  {
    margin-top: 16px;
    max-height: 550px;
    padding-right: 16px;
    overflow-y: auto;

    > div {

      &:not(:last-child)  {
        margin-bottom: 16px;
      }
    }
  }
}
