@import '../../variable';

.mobileMenuContainer {
  padding: unit(16px);
  padding-bottom: 0;

  .headerText {
    p {
      font-weight: 400;

      &:nth-child(1) {
        font-size: unit(18px);
        color: $lightGray100;
      }

      &:nth-child(2) {
        font-size: unit(12px);
        color: $gray75;
      }
    }
  }

  .menuIcon {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .menuContent {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 999;
    background-color: #303030f3;

    .closeIcon {
      cursor: pointer;
      position: absolute;
      z-index: 999;
      top: 16px;
      right: 16px;
      width: 32px;
    }

    .linkContainer {
      text-align: center;
      margin-top: 150px;

      .menuText {
        font-size: unit(28px);
        font-weight: 400;
        color: $white;
        text-transform: uppercase;
        margin-bottom: 100px;
      }

      .menuLink {
        text-decoration: none;
        font-size: unit(20px);
        font-weight: 400;
        color: $white;
        margin-bottom: 16px;
        display: block;
      }
    }

    .imgContainer {
      margin-top: auto;

      >div {
        flex: 0 0 15%;
        max-width: 15%;

        &:not(:last-child) {
          margin-right: 32px;
        }

        .imgBtn {
          background: transparent;
          border: 0;
          display: block;
          padding: 0;
          margin: 0;
          width: 100%;
          .flagImg {
            width: 100%;
            height: auto;
            display: block;
          }
        }

      }
    }

    .footerContainer {
      background: $black100;
      margin-top: 16px;
      padding: unit(16px) unit(64px);
    }
  }
}