@import '../../variable';

.tableMainContainer {
  background-color: $black50;
  border-radius: 16px;
  overflow: hidden;
  padding: unit(32px) unit(16px);
  color: $white;

  .tableTitle {
    color: $white;
    font-size: unit(22px);
    font-weight: 500;
    margin-bottom: 32px;
  }

  .tableContainer {
    // margin-top: 32px;


    .tableHeading {
      color: $blue25;
    }

    .tableRow {
      font-weight: 400;
      font-size: unit(18px);

      >div {
        flex: 0 0 10%;
        padding-bottom: unit(48px);
        padding-right: unit(16px);
        padding-left: unit(8px);

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(7) {
          flex: 0 0 19.33%;
        }

        &:nth-child(6) {
          flex: 0 0 12%;
        }

        &:not(:last-child) {
          border-right: 1px solid $gray75;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      &:last-child {
        >div {
          padding-bottom: 0;
        }
      }

      &:first-child {
        >div {
          padding-bottom: unit(16px);
        }
      }

      .detailsBtnContainer {
        flex: 0 0 40%;

        .detailsBtn {
          width: 100%;
          font-size: unit(14px);
          text-transform: capitalize;
          border: 2px solid $lightBlue100;
          background: $blue100;
          color: white;
        }
      }
    }
  }
}