@import '../../../../variable';

.profileContainer {
  .tagContainer {
    color: white;
    margin-top: 32px;
  }
}

@media (min-width: $width992) {
  .profileContainer {
    >div {
      &:nth-child(1) {
        flex: 0 0 35%;
        margin-right: 32px;
      }

      &:nth-child(2) {
        flex: 0 0 40%;
      }
    }
  }
}