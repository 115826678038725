@import '../../variable';

.otherContainer {
  .label {
    margin-bottom: 16px;
    color: $lightGray100;
    font-weight: 400;
  }

  .firstColumn {
    margin-bottom: 16px;

    .flexContainer {
      margin-bottom: 16px;

      .flexDiv {
        margin-bottom: 16px;

        >div {
          background: $lightGray100;
          flex: 0 0 48%;
          max-width: 48%;
          border-radius: 5px;
          color: $black50;
          font-weight: 400;
        }
      }

    }

    .cityZipContainer {
      >div {
        div {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .otherContainer {
    .label {
      margin-bottom: 16px;
    }

    .firstColumn {
      margin-bottom: 0;
      flex: 0 0 65%;
      padding-right: unit(70px);

      .flexContainer {
        margin-bottom: 32px;

        .flexDiv {
          margin-bottom: 16px;

          >div {
            flex: 0 0 37.5%;
            max-width: 37.5%;

            &:nth-child(1) {
              margin-right: 5%;
            }
          }

          &:not(:last-child) {
            margin-bottom: 32px;
          }
        }

      }

      .flexContainer,
      .cityZipContainer {
        >div {

          &:nth-child(1) {
            flex: 0 0 20%;
            max-width: 20%;

            .label {
              margin-bottom: 0;
            }
          }

          &:nth-child(2) {
            flex: 0 0 80%;
            max-width: 80%;

            .label {
              width: 80%;
            }
          }
        }
      }

      .cityZipContainer {
        >div {
          div {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            .label {
              width: auto;
              margin-bottom: 0;
              text-align: right;
            }

            >div {
              flex: 0 0 50%;
              max-width: 50%;

              &:nth-child(1) {
                flex: 0 0 35%;
                max-width: 35%;
              }
            }
          }
        }
      }
    }

    .secondColumn {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }
}