@import '../../variable';

.taskListContainer {
  border-radius: 16px;
  // color: $lightGray100;
  background: $black75;
  padding: unit(32px);

  .listHeader {
    p {
      font-size: unit(14px);

      &:nth-child(1) {
        font-size: unit(26px);
        text-transform: uppercase;
      }
    }
  }

  .ItemsContainer {
    margin-top: 32px;

    .ItemsLink {
      text-decoration: none;
      // color: $lightGray100;
      display: block;

      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
}

@media (min-width: $width992) {
  .taskListContainer {
    padding: unit(16px);
    background: transparent;

    .listHeader {
      p {
        font-size: unit(26px);
      }
    }
  }
}