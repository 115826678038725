@import '../../../variable';

.homePageContainer {
  .pageTitleContainer {
    margin-bottom: 16px;

    > div {
      &:nth-child(2)  {
        flex: 0 0 140px;
      }
    }
  }

  .overviewCardsContainer {
    > div {
      margin-bottom: 16px;
    }
  }

  .calendarListContainer {
    > div {
      margin-bottom: 16px;
    }
  }

  .LatestJobsContainer {
    width: 100%;
  }
}

@media (min-width: $width992) {
  .homePageContainer {
    .pageTitleContainer {
    
        >div {
          &:nth-child(2) {
            flex: 0 0 160px;
          }
        }
      }
    .overviewCardsContainer {
      margin-bottom: 16px;

      > div {
        margin-bottom: 0;
        flex: 0 0 32%;
        max-width: 32%;
      }
    }

    .calendarListContainer {
      margin-bottom: 16px;
      
      .calendarContainer {
          flex: 0 0 68%;
          max-width: 68%;
          background: $black50;
          padding: unit(16px);
          border-radius: 16px;
          margin-bottom: 0;
        }

        .taskListContainer {
          flex: 0 0 31%;
          max-width: 31%;
          margin-bottom: 0;
          background: $black50;
          border-radius: 16px;
          color: white;
        }

        // &.LightContainer {
        //   .calendarContainer,
        //   .taskListContainer  {
        //     background: $white;
        //   }

        //   .taskListContainer  {
        //     background: $white;
        //     color: $black50;
        //   }
        // }
    }

    .LatestJobsContainer {
      width: 100%;
    }
  }
}
