@import '../../variable';

.projectBtn {
  background: transparent;
  border: 0;
  border-radius: 5px;
  font-size: unit(16px);
  text-align: center;
  font-weight: 500;
  width: 100%;
  display: block;
  padding: unit(4px) 0;
  text-transform: uppercase;

  >svg {
    width: 20px;
    display: block;
  }
}

@media (min-width: $width992) {
  .projectBtn {
    padding: unit(8px) 0;
  }

  .editBtn {
    padding: unit(4px) 0;
  }
}

.saveBtn {
  background: $green100;
  color: $white;
}

.warnBtn {
  background: $yellow100;
  color: $white;
}

.activeBtn {
  background: $green100;
  color: $white;
}

// .addBtnBlue {
//   border-radius: 16px;
// }

.addBtnBlue,
.addBtnOrange {
  background: $blue25;
  color: white;
  font-weight: 400;
  text-transform: capitalize;
}

.addBtnOrange {
  background: $orange;
}

.backBtn {
  background: $blue25;
}

.addBtn {
  background: $gray100;
  color: white;
}

.editBtn {
  background: $lightGray100;
  color: $black50;
}

.delBtn {
  background: $red;
  color: $white;
}

.addBtnLg {
  background: $orange;
  color: $white;
}

.disabled{
  background: $lightGray100;
  color: rgb($black50,0.5);
}


// icon buttons

.iconAddBtn,
.iconEditBtn,
.iconDelBtn {
  padding: 0;
}

.iconAddBtn {
  >svg {
    fill: $green100;
  }
}


.iconEditBtn {
  >svg {
    fill: $blue25;
  }
}

.iconDelBtn {
  >svg {
    fill: $red;
  }
}