@import '../../variable';

.addUserCardContainer {
  border-radius: 16px;
  background: $black50;
  padding: unit(16px) unit(32px);

  .addUserBtnContainer {
    .addUserBtnIcon {
      width: 65px;
      height: 65px;
      flex-shrink: 0;
    }

    p {
      font-weight: 500;
      font-size: unit(15px);
      color: $gray75;
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
}

@media (min-width: $width992) {
  .addUserCardContainer {
      background: $black75;
      padding: unit(64px) 0;
      // height: 100%;
  
      .addUserBtnContainer {
  
        .addUserBtnIcon {
          width: 125px;
          height: 125px;
        }
  
        p {
          display: none;
        }
      }
    }
}