@import '../../variable';

.commonStatus {
  border-radius: 5px;
  font-size: unit(16px);
  text-align: center;
  font-weight: 500;
  width: 100%;
  display: block;
  padding: unit(4px);
  text-transform: uppercase;
}

.active {
  background: $green100;
}

.inactive {
  background: $gray75;
}

.pending {
  background: $orange;
}



.assigned{
  background: $lightBlue50;
}

.available{
  background: $lightBlue100;
}

.archived{
  background: $redLight;
}

.completed {
  background: $yellow100;
}

.invoiced {
  background: $lightGreen;
}

.new {
  background: $blue25;
}

.inProgress{
  background: $purpleLight;
}

.admin {
  background: $blue25;
}

.text {
  background: transparent;
}