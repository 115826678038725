@import '../../variable';

.circularProgress {
  position: relative;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.circularProgress:before {
  content: '';
  position: absolute;
  height: 90%;
  width: 90%;
  background-color: $black50;
  border-radius: 50%;
}
.valueContainer {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  color: $white;
}
