@import "../../../variable";

.companyDetailsContainer {
  margin-top: 16px;
  border: 0;
  border-radius: 16px;
  background: $black50;
  display: block;

  .scrollContainer {
    padding: 0;
  }

}

@media (min-width: $width992) {
  .companyDetailsContainer {
    width: 96%;

    .scrollContainer {
      height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }
}