@import '../../variable';

.CardMainContainer {
  background: $lightGray100;
  border-radius: 5px;

  .headerContainer {
    padding: unit(16px);

    .taskTitle {
      font-size: unit(18px);
      font-weight: 500;
      color: $black50;
      margin: 0;
      flex: 0 0 70%;
      max-width: 70%;
    }

    .TitleBtnContainer {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }

  .CardContainer {
    .firstCard {
      .header {
        background: $lightGray0;
        padding: unit(16px);

        >div {
          &:nth-child(1) {
            flex: 0 0 70%;
            max-width: 70%;
          }

          &:nth-child(2) {
            flex: 0 0 30%;
            max-width: 30%;
          }
        }
      }

      .CardBody {
        padding: unit(16px);
        border-bottom: 1px solid $lightGray0;

        .contentTitle {
          font-size: unit(16px);
          font-weight: 300;
          color: $black75;
          margin-bottom: 8px;
        }

        .contentDec {
          font-size: unit(14px);
          font-weight: 500;
          color: $black50;
        }

        .BodyContent {
          margin-bottom: 32px;

          >div {
            &:nth-child(1) {
              flex: 0 0 70%;
              max-width: 70%;
              padding-right: unit(32px);
            }

            &:nth-child(2) {
              flex: 0 0 30%;
              max-width: 30%;
            }
          }
        }
      }

      &:nth-child(2) {
        .header {
          background: transparent;
          padding-bottom: 0;

          p {
            color: $black75;
            margin-bottom: 0;
          }
        }

        .CardBody {
          border-bottom: 0;
          > div {
            &:not(:last-child)  {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: $width992) {
  .CardMainContainer {
    // background: $lightGray100;
    // border-radius: 5px;

    .headerContainer {
      // padding: unit(16px);
      border-bottom: 1px solid $lightGray0;

      .taskTitle {
        // font-size: unit(18px);
        // font-weight: 500;
        // color: $black50;
        // margin: 0;
        flex: 0 0 85%;
        max-width: 85%;
      }

      .TitleBtnContainer {
        flex: 0 0 15%;
        max-width: 15%;
      }
    }

    .CardContainer {
      .firstCard {
        &:nth-child(1) {
          flex: 0 0 40%;
          border-right: 1px solid $lightGray0;
        }

        &:nth-child(2) {
          flex: 0 0 60%;
        }

        .header {
          // background: $lightGray0;
          // padding: unit(16px);
          // >div {
          //     &:nth-child(1) {
          //       flex: 0 0 70%;
          //     }

          //     &:nth-child(2) {
          //       flex: 0 0 30%;
          //     }
          //   }
        }

        .CardBody {
          // padding: unit(16px);
          border-bottom: 0;

          .contentTitle {
            // font-size: unit(16px);
            // font-weight: 300;
            // color: $black75;
            // margin-bottom: 8px;
          }

          .contentDec {
            // font-size: unit(14px);
            // font-weight: 500;
            // color: $black50;
          }

          .BodyContent {
            // margin-bottom: 32px;

            >div {
              &:nth-child(1) {
                flex: 0 0 65%;
                max-width: 65%;
                // padding-right: unit(32px);
              }

              &:nth-child(2) {
                flex: 0 0 35%;
                max-width: 35%;
              }
            }
          }
        }

        &:nth-child(2) {
          .header {
            // background: transparent;
            // padding-bottom: 0;

            p {
              // color: $black75;
              // margin-bottom: 0;
            }
          }

          .CardBody {
            // border-bottom: 0;
            height: calc(100vh - 480px);
            overflow-y: auto;
            // padding: 16px;
          }
        }
      }
    }
  }
}