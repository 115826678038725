@import '../../variable';

.consentContainer {
  .label {
    margin-bottom: 16px;
    color: $lightGray100;
    font-weight: 400;
  }

  .labelContainer {
    .label {
      margin-bottom: 32px;
    }
  }

  .flexDiv {
    >div {
      flex: 0 0 48%;
      max-width: 48%;
      color: $black50;
      font-weight: 400;
      background: $lightGray100;
      border-radius: 5px;
    }
  }
}

@media (min-width: $width992) {
  .consentContainer {
    padding-top: unit(32px);
    width: 70%;

    .label {
      width: 100%;
    }

    .labelContainer {
      padding-left: calc(11% + 5px);
    }

    .flexDiv {
      padding-left: calc(11% + 5px);

      >div {
        flex: 0 0 23%;
        max-width: 23%;
        margin-right: 16px;
      }
    }
  }
}