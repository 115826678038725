@import '../../variable';

.iconContainer {
  .downBtn,
  .TrashBtn {
    width: 16px;
    svg {
      fill: white;
    }
  }

  .downBtn {
    margin-right: 16px;
  }
}
