@import "../../variable";

@media (min-width: $width992) {
  .uploadAssignedContainer {
    >div {
      min-height: 350px;

      >div {
        height: 100%;

        >div {
          height: 100%;

          >div {
            &:nth-child(1) {

              >div {
                &:nth-child(2) {
                  margin-bottom: 32px;
                }
              }
            }
            &:nth-child(2) {
              position: absolute !important;
              left: 0;
              bottom: 16px;
            }
          }
        }
      }
    }
  }
}