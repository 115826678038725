@import "../../variable";

.logoContainer {
  width: 100%;

  .logoImg  {
    width: 100%;
    height: auto;
    display: block;
  }
}