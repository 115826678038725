@import '../../variable';

.notFoundContainer {
  width: 100%;
  height: 100%;

  p {
    &:nth-child(2) {
      font-size: unit(32px);
      font-weight: 900;
      margin: 32px 0;
    }
  }

  .backHomeBtn {
    text-decoration: none;
  }
}

@media (min-width: $width992) {
  .notFoundContainer {
      // width: 100%;
      // height: 100%;
  
      // p {
      //   &:nth-child(2) {
      //     font-size: unit(32px);
      //     font-weight: 900;
      //     margin: 32px 0;
      //   }
      // }
  
      // .backHomeBtn {
      //   text-decoration: none;
      // }
    }
}