@import '../../../../variable';

.dashBoardContainer {
  .titleText {
    color: $lightGray100;
    font-weight: 400;
    font-size: unit(14px);
    margin-bottom: 16px;
  }

  >div {
    &:nth-child(2) {
      margin-top: 32px;

      >div:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}