@import "../../variable";

.loginContainer {
  height: 100vh;
  background: $black100;
  padding: unit(32px);
  position: relative;

  .logoContainer {
    width: 70%;
    margin-bottom: 120px;
  }

  .formMainContainer {
    width: 100%;
  }
}

@media (min-width: $width992) {
  .loginContainer {
    .logoContainer {
      width: 17%;
      margin-bottom: 80px;
    }

    .formMainContainer {
      width: 25%;
    }
  }
}

