$black100:#000000;
$black75:#202020;
$black50:#303030;
$orange:#D56730;
$red:#EC4E20;
$gray100:#707070;
$gray75:#878793;
$lightGray100:#EEEEEE;
$lightGray75:#EBEBED;
$lightGray50:#FEFEFE;
$lightGray25:#E6E6E6;
$lightGray0:#DCDCDC;
$blue100:#2B3441;
$blue50:#3A3952;
$blue25:#74BDDD;
$lightBlue100:#4D5C72;
$lightBlue75:#809FB8;
$lightBlue50:#99B2C6;
$skyBlue100:#74BDDD;
$green100:#1AD598;
$yellow100:#C9A800;
$yellow75:#FFD500;
$purple100:#7F63F4;
$white:white;
$lightGreen:#5BB318;
$lightGreen0:#BDF2D5;
$purpleLight:#7C3E66;
$lightWhite:#FFFFFF;
$redLight:#EB1D36;

// change pixel to rem
@function unit($pixels, $context: 16px) {
  @return ($pixels / $context) * 1rem;
}

$headerHeight:60px;
$mobTabHeight: 60px;
$mobTabHeight: 60px;
$mobHeaderHeight: 50px;
$radius16px:16px;

// for media quarry
$width576: 576px;
$width768: 768px;
$width992: 992px;
$width1200: 1200px;
$width1440: 1440px;
$width1920: 1920px;