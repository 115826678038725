@import '../../variable';

.workTypeMainDiv {
  .workTypeDiv {
    border-radius: 5px;
  }
}


@media (min-width: $width992) {

  .textRight {
    text-align: right;
  }

  .workTypeMainDiv {
    margin-left: 0;
    position: relative;

    .workTypeDiv  {
      position: absolute;
      top: 0;
      left: 16px;
      z-index: 100;
    }
  }

}