@import '../../variable';

.commentsDiv {
  background: $lightGray100;
  padding: unit(64px) unit(32px);
  border-radius: 5px;
  color: $black50;
  font-size: unit(14px);

  .innerDiv {
    background: white;
    border-radius: 5px 5px 0 5px;
    padding: unit(32px) unit(16px);
    clip-path: polygon(0% 0%, 100% 0%, 100% 68%, 100% 87%, 100% 100%, 97% 91%, 0 90%);
  }

  .createdDiv {
    text-align: right;
    margin-top: 10px;
  }
}

@media (min-width: $width992) {
  .commentsDiv {
    min-height: calc(100vh - 370px);
    font-weight: 400;
  }
}