@import '../../../variable';

.invoiceMainContainer {
  .invoiceContainer {
    margin-top: 16px;

    .firstColumn {
      .titleContainer {
        >div {
          flex: 0 0 50%;
          max-width: 50%;
          margin-bottom: 32px;

          p {
            color: $green100;
            font-size: unit(18px);
            font-weight: 500;

            &:nth-child(1) {
              font-size: unit(14px);
              font-weight: 300;
              margin-bottom: 16px;
            }
          }

          &:nth-child(3),
          &:nth-child(4) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      .timeSheetMainContainer {
        margin: 0;
        background: $black75;
        border-radius: 16px;
        overflow-x: auto;
        overflow-y: hidden;

        >div {
          &:not(:first-child) {
            margin-left: 32px;
          }
        }
      }
    }

    .secondColumn {
      .titleContainer {
        margin: 32px 0;

        >div {
          flex: 0 0 50%;
          max-width: 50%;

          &:nth-child(1) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 32px;
          }

          p {
            color: $yellow75;
            font-size: unit(14px);
            font-weight: 300;

            &:nth-child(2) {
              font-size: unit(18px);
              font-weight: 500;
              margin-top: 8px;
            }
          }
        }
      }

      .invoiceItemMainContainer {
        margin: 0;
        background: $black75;
        border-radius: 16px;
        padding: unit(16px) 0;

        .btnContainer {
          padding: 0 unit(16px);

          >div {
            margin-top: 16px;
          }
        }
      }

    }
  }
}

@media (min-width: $width992) {
  .invoiceMainContainer {
    .invoiceContainer {
      background: $black50;
      padding: unit(32px);
      border-radius: 16px;

      .firstColumn {
        flex: 0 0 60%;
        max-width: 60%;
        padding-right: unit(32px);

        .titleContainer {
          >div {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding-right: unit(32px);

            &:nth-child(3),
            &:nth-child(4) {
              flex: 0 0 33.33%;
              max-width: 33.33%;
            }
          }
        }

        .timeSheetMainContainer {
          overflow-x: hidden;
          overflow-y: auto;
          height: calc(100vh - 300px);

          >div {
            &:not(:first-child) {
              margin-left: 0;
            }
          }
        }
      }

      .secondColumn {
        flex: 0 0 40%;
        max-width: 40%;

        .titleContainer {
          margin-top: 0;
          padding: 0 unit(32px);

          >div {
            flex: 0 0 auto;
            text-align: center;

            &:nth-child(1) {
              flex: 0 0 auto;
              margin-bottom: 0;
            }
          }
        }

        .invoiceItemMainContainer {
          background: transparent;
          border-radius: 0;
          padding: 0;

          .btnContainer {
            margin-top: 16px;
            padding: 0;
            background: transparent;

            >div {
              margin-top: 0;
              width: 40%;

              &:nth-child(1) {
                margin-right: 16px;
              }
            }
          }
        }
      }
    }
  }
}