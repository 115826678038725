@import "../../../variable";

.projectViewContainer {

  .plusIcon {
    margin-right: 6px;
  }


  .buttonContainer {
    margin: 16px 0;


    >div {

      &:nth-child(1) {
        flex: 0 0 50%;
      }

      &:nth-child(2) {
        flex: 0 0 45%;
      }
    }
  }


  .projectViewCard {
    background-color: $black50;
    border-radius: $radius16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    position: relative;
    color: $white;
    margin-bottom: 16px;
    border: 0;


    .accordionBorder {
      border-bottom: 2px solid rgba(128, 128, 128, 0.232);
      padding: unit(16px);
    }

    .CardBody {
      padding: unit(16px);
      background: transparent;
      position: relative;

      &.activeCard {
        background: rgba($orange, 0.1);
      }

      .CardColumn {
        flex: 0 0 48%;
        max-width: 48%;

        &:nth-child(1),
        &:nth-child(2) {
          margin-bottom: 24px;
        }

        .columnTitle {
          text-transform: uppercase;
          font-weight: 400;
          font-size: unit(16px);
        }

        p {
          font-size: unit(14px);

          &:first-child {
            margin-bottom: 8px;
          }

          span {
            color: $gray75;
            margin-right: 8px;
          }
        }
      }

      .btnMainColumn {
        flex: 0 0 100%;
        margin-bottom: 24px;

        .btnContainer {
          width: 35%;
        }
      }

      .downBtnColumn {
        width: unit(28px);
        background: transparent;
        border: 0;
        box-shadow: none;
        outline: 0;

        svg {
          fill: white;
        }
      }

      .actionMenuMainContainer  {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;

        .actionMenuContainer  {
          margin-right: 16px;
        }
      }
    }
  }

}

@media (min-width: $width992) {
  .projectViewContainer {

    >div:first-child {
      margin-bottom: 32px;
    }

    .buttonContainer {
      margin: 0;
      flex: 0 0 1;

    >div {
        &:nth-child(1) {
          flex: 0 0 200px;
          max-width: 200px;
          margin-right: 16px;
        }
    
        &:nth-child(2) {
          flex: 0 0 160px;
          max-width: 160px;
        }
      }

    }

    .projectViewCard {

      .accordionBorder {
        padding: unit(16px) unit(32px);

        >div {
          width: 25%;
        }
      }

      .CardBody {
        padding: unit(16px) unit(32px);

        .CardColumn {
          flex: 0 0 18%;
          max-width: 18%;

          &:nth-child(1),
          &:nth-child(2) {
            margin-bottom: 0;
          }

          .columnTitle {
            font-size: unit(18px);
          }

          p {
            font-size: unit(16px);

            &:first-child {
              margin-bottom: 16px;
            }
          }
        }

        .btnMainColumn {
          flex: 0 0 150px;
          margin-bottom: 0;

          .btnContainer {
            width: 100%;
          }
        }

        .downBtnColumn {
          top: 2px;
          right: 12px;
        }

                 .actionMenuMainContainer {
                   position: static;
                   top: auto;
                   right: auto;
                   z-index: auto;
        
                   .actionMenuContainer {
                     margin-right: 0;
                     position: absolute;
                     top: 6px;
                     right: 18px;
                     z-index: 100;
                   }
                 }
      }
    }
  }
}