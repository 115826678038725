@import '../../variable';

.inputField {
  min-height: 120px;
}

.btnContainer {
  margin-top: 16px;
}

@media (min-width: $width992) {
  .btnContainer {
    width: 60%;
  }
}