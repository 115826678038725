@import '../../variable';

.formContainer {
  margin-top: 32px;

  p {
    color: $lightGray100;
    margin-bottom: 16px;
  }

  >div {
    margin-bottom: 16px;

    .inputField {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $width992) {
  .formContainer {
    margin-top: 0;
  }
}